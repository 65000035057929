import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import config from '@config';
import * as Sentry from '@sentry/angular';
import { AuthService } from '@services/auth.service';
import { HttpError } from '@services/http-error';
import { LogOptions } from '@shared/models/log-options.model';
import { LogUser } from '@shared/models/log-user.model';
import { isError, isObject, isString } from 'lodash';

@Injectable({ providedIn: 'root' })
export class LogService {
  readonly authService = inject(AuthService);
  user = signal<LogUser | null>(null);

  constructor() {
    this.init();
    this.watchUser();
  }

  private init(): void {
    const integrations = [];
    if (config.sentry.replayEnabled) {
      integrations.push(Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }));
    }
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.name,
      release: config.version,
      enabled: config.sentry.enabled,
      integrations,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }

  private watchUser(): void {
    this.authService.authStatusChanged.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        const currentUser = this.authService.getCurrentUser();
        this.user.set({
          email: currentUser.email,
          username: currentUser.username,
          role: currentUser.role,
        });
      } else {
        this.user.set(null);
      }
      Sentry.setUser(this.user());
    });
  }

  message(message: string, options?: LogOptions): void {
    this.log(message, options);
  }

  messageWithAction(message: string, action: string, options?: LogOptions): void {
    options = Object.assign(options || {}, { tags: { action } });
    this.message(message, options);
  }

  error(error: Error, options?: LogOptions): void {
    if (error instanceof HttpErrorResponse) {
      console.debug(error);
      error = new HttpError(error);
    }
    if (!(error instanceof Error)) {
      error = new Error(this.getErrorMessage(error));
    }
    this.log(error, options);
  }

  errorWithAction(message: string, action: string, options?: LogOptions): void {
    options = Object.assign(options || {}, { tags: { action } });
    this.error(new Error(message), options);
  }

  private log(item: Error | string, options?: LogOptions): void {
    this.logToConsole(item, options);
    const defaultSeverity = isError(item) ? 'error' : 'info';

    if (!config.sentry.enabled) {
      return;
    }

    if (item instanceof Error && item.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      return;
    }

    Sentry.withScope(scope => {
      scope.setLevel(options?.severity ?? defaultSeverity);
      scope.setUser(this.user());
      scope.setExtras({ ...options?.extraInfo, ...this.getLocalStorage() });
      scope.setTags(options?.tags ?? {});
      isError(item) ? scope.captureException(item) : scope.captureMessage(item);
    });
  }

  private logToConsole(logItem: Error | string, options?: LogOptions): void {
    isError(logItem) ? console.error(logItem) : console.log(logItem);
    console.debug(options);
  }

  private getErrorMessage(error: unknown): string {
    if (isObject(error) && 'message' in error && isString(error.message)) {
      return error.message;
    }
    return JSON.stringify(error);
  }

  private getLocalStorage() {
    const result: Record<string, string> = {};
    Object.keys(localStorage)
      .filter(key => key.startsWith('_'))
      .forEach(key => (result[key] = localStorage.getItem(key) || ''));
    return result;
  }
}
